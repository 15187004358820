import validator from 'cpf-cnpj-validator';
import isMobile from '../lib/isMobile';

const Joi = require('joi').extend(validator);
const validatorEmail = require('email-validator');

export default {
  methods: {
    isMobile,
    isValidEmail(email) {
      if (!email) return false;

      return validatorEmail.validate(email);
    },
    isDDDValid(phone) {
      const dddObject = {
        11: { city: 'São Paulo', state: 'SP' },
        12: { city: 'São José dos Campos', state: 'SP' },
        13: { city: 'Santos', state: 'SP' },
        14: { city: 'Bauru', state: 'SP' },
        15: { city: 'Sorocaba', state: 'SP' },
        16: { city: 'Ribeirão Preto', state: 'SP' },
        17: { city: 'São José do Rio Preto', state: 'SP' },
        18: { city: 'Presidente Prudente', state: 'SP' },
        19: { city: 'Campinas', state: 'SP' },
        21: { city: 'Rio de Janeiro', state: 'RJ' },
        22: { city: 'Campos dos Goytacazes', state: 'RJ' },
        24: { city: 'Volta Redonda', state: 'RJ' },
        27: { city: 'Vila Velha/Vitória', state: 'ES' },
        28: { city: 'Cachoeiro de Itapemirim', state: 'ES' },
        31: { city: 'Belo Horizonte', state: 'MG' },
        32: { city: 'Juiz de Fora', state: 'MG' },
        33: { city: 'Governador Valadares', state: 'MG' },
        34: { city: 'Uberlândia', state: 'MG' },
        35: { city: 'Poços de Caldas', state: 'MG' },
        37: { city: 'Divinópolis', state: 'MG' },
        38: { city: 'Montes Claros', state: 'MG' },
        41: { city: 'Curitiba', state: 'PR' },
        42: { city: 'Ponta Grossa', state: 'PR' },
        43: { city: 'Londrina', state: 'PR' },
        44: { city: 'Maringá', state: 'PR' },
        45: { city: 'Foz do Iguaçú', state: 'PR' },
        46: { city: 'Francisco Beltrão/Pato Branco', state: 'PR' },
        47: { city: 'Joinville', state: 'SC' },
        48: { city: 'Florianópolis', state: 'SC' },
        49: { city: 'Chapecó', state: 'SC' },
        51: { city: 'Porto Alegre', state: 'RS' },
        53: { city: 'Pelotas', state: 'RS' },
        54: { city: 'Caxias do Sul', state: 'RS' },
        55: { city: 'Santa Maria', state: 'RS' },
        61: { city: 'Brasília', state: 'DF' },
        62: { city: 'Goiânia', state: 'GO' },
        63: { city: 'Palmas', state: 'TO' },
        64: { city: 'Rio Verde', state: 'GO' },
        65: { city: 'Cuiabá', state: 'MT' },
        66: { city: 'Rondonópolis', state: 'MT' },
        67: { city: 'Campo Grande', state: 'MS' },
        68: { city: 'Rio Branco', state: 'AC' },
        69: { city: 'Porto Velho', state: 'RO' },
        71: { city: 'Salvador', state: 'BA' },
        73: { city: 'Ilhéus', state: 'BA' },
        74: { city: 'Juazeiro', state: 'BA' },
        75: { city: 'Feira de Santana', state: 'BA' },
        77: { city: 'Barreiras', state: 'BA' },
        79: { city: 'Aracaju', state: 'SE' },
        81: { city: 'Recife', state: 'PE' },
        82: { city: 'Maceió', state: 'AL' },
        83: { city: 'João Pessoa', state: 'PB' },
        84: { city: 'Natal', state: 'RN' },
        85: { city: 'Fortaleza', state: 'CE' },
        86: { city: 'Teresina', state: 'PI' },
        87: { city: 'Petrolina', state: 'PE' },
        88: { city: 'Juazeiro do Norte', state: 'CE' },
        89: { city: 'Picos', state: 'PI' },
        91: { city: 'Belém', state: 'PA' },
        92: { city: 'Manaus', state: 'AM' },
        93: { city: 'Santarém', state: 'PA' },
        94: { city: 'Marabá', state: 'PA' },
        95: { city: 'Boa Vista', state: 'RR' },
        96: { city: 'Macapá', state: 'AP' },
        97: { city: 'Coari', state: 'AM' },
        98: { city: 'São Luís', state: 'MA' },
        99: { city: 'Imperatriz', state: 'MA' },
      };
      const res = dddObject[phone.substring(0, 2)];
      return res !== undefined;
    },
    isValidPhone(phone) {
      if (!phone) return false;
      const filtered = phone.replace(/[() -]/g, '');
      if (filtered.length !== 11) return false;
      const split = filtered.split('');
      if (split[2] !== '9') return false;
      if (!this.isDDDValid(filtered)) return false;
      return true;
    },
    isValidCpf(cpf) {
      if (!cpf) return false;
      const filtered = cpf.replace(/[. -]/g, '');
      const cpfSchema = Joi.document().cpf();
      const result = cpfSchema.validate(filtered);
      if (result.error) return false;
      return true;
    },
    isValidZipCode(zipCode) {
      return zipCode && zipCode.replace('-', '').length === 8;
    },
    isValidShipping(shipping) {
      if (!shipping) return false;

      const shippingValidations = {
        zipCode(zipCode) {
          if (!zipCode) return false;
          return zipCode.length === 8;
        },
        default(key, value) {
          if (key !== 'complement' && [undefined, null].includes(value)) {
            return false;
          }
          return true;
        },
      };

      const keys = Object.keys(shipping);
      let isValid = true;

      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        const value = shipping[key];

        const isValidKey = (shippingValidations[key] && shippingValidations[key](value))
          || shippingValidations.default(key, value);

        if (!isValidKey) {
          isValid = false;
          break;
        }
      }

      return isValid;
    },
    getInvalidInputs({ email, cpf, phone, shipping }) {
      const invalidInputs = [];
      if (!this.isValidEmail(email)) invalidInputs.push('email');
      if (!this.isValidCpf(cpf)) invalidInputs.push('cpf');
      if (!this.isValidPhone(phone)) invalidInputs.push('phone');
      if (!this.isValidShipping(shipping)) invalidInputs.push('shipping');
      return invalidInputs;
    },
    hideEmail(email) {
      if (!email) return email;
      const split = email.split('@');
      const first = split[0];
      if (first === '') return email;
      const asteriskNumber = 6;
      const asterisk = Array(asteriskNumber).join('*');
      const username = `${
        first.length >= 4 ? first.substring(0, 2) : first.substring(0, 1)
      }${asterisk}${first.substring(first.length - 2)}`;
      split[0] = username;
      return split.join('@');
    },
    hidePhone(number) {
      if (!number) return number;
      const phone = number.toString();

      const cleanPhoneString = phone.replace(/[^\d]/g, '');
      const formatedPhone = cleanPhoneString.replace(/(\d{2})(\d{8})/, '($1) $2');

      return `${formatedPhone.substring(0, 9)} *****`;
    },

    hideCPF(number) {
      if (!number) return number;
      const cpf = number.toString();

      const cleanCPFString = cpf.replace(/[^\d]/g, '');
      const formatedCPF = cleanCPFString.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

      return `${formatedCPF.substring(0, 4)}***.***${formatedCPF.substring(11, 14)}`;
    },
    hideCEP(number) {
      if (!number) return number;
      const cep = number.toString();
      const cleanCEPString = cep.replace(/[^\d]/g, '');
      const formatedCEP = cleanCEPString.replace(/(\d{5})(\d{3})/, '$1-$2');

      return `${formatedCEP.substring(0, 2)}***-**${formatedCEP.substring(8, 9)}`;
    },
  },
};
